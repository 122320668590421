import React from 'react';
import { Card, Descriptions } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';



const SegmentDetailsCard = ({ segment, loading }) => {
  return (
    <Card title="Segment Information" loading={loading}>
      <Descriptions>
        <Descriptions.Item label="Name">{segment?.name}</Descriptions.Item>
        <Descriptions.Item label="Status">{segment?.status}</Descriptions.Item>
        <Descriptions.Item label="Total Users">{segment?.users_count}</Descriptions.Item>
        <Descriptions.Item label="Created At">
          {moment(segment?.created_at).utc(false).format('YYYY-MM-DD HH:mm')}
        </Descriptions.Item>
        <Descriptions.Item label="Last Updated">
          {moment(segment?.last_updated).utc(false).format('YYYY-MM-DD HH:mm')}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};


SegmentDetailsCard.propTypes = {
  segment: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};


export default SegmentDetailsCard;
