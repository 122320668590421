import React, { useEffect } from 'react';
import { Breadcrumb, Spin } from 'antd';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import urlPageAbTests from '../../../../urls/urlPageAbTests';
import urlPageAbTestDetails from '../../../../urls/urlPageAbTestDetails';
import FormCreateEditAbTest from '../../forms/FormCreateEditAbTest';
import {
  getAbTestDetails,
  createAbTest,
  updateAbTest,
  selectData,
  selectLoading,
  selectCreateAbTestLoading,
  selectUpdateAbTestLoading,
  selectUpdateAbTestErrors,
  selectCreateAbTestErrors,
} from '../../feature/abTestDetailsSlice';
import {
  CREATE_AB_TEST_INITIAL_VALUES,
  getPreparedFormData,
  preparedToRequestFormData,
} from './PageCreateEditAbTest.const';
import { Permissions } from '../../../../const/permissions';
import HasRights from '../../../../components/HasRights';
import { useApp } from '../../../../app/context/AppContext';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import {
  getSegmentsList,
  selectSegmentsList,
  resetSegmentsList,
} from '../../../Segments/feature/segmentsSlice';



const PageCreateEditAbTest = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const { abTestName } = useParams();
  const { projectSettings } = useApp();
  const dispatch = useDispatch();
  const abTestDetails = useSelector(selectData);
  const isLoading = useSelector(selectLoading);
  const isCreateLoading = useSelector(selectCreateAbTestLoading);
  const isUpdateLoading = useSelector(selectUpdateAbTestLoading);
  const updateErrors = useSelector(selectUpdateAbTestErrors);
  const createErrors = useSelector(selectCreateAbTestErrors);

  const segmentList = useSelector(selectSegmentsList);

  useEffect(() => {
    dispatch(getSegmentsList());
    return () => {
      dispatch(resetSegmentsList());
    };
  }, []);

  const isCreateAbTestPage = pathname.endsWith('create');

  useEffect(() => {
    if (!isCreateAbTestPage) {
      dispatch(getAbTestDetails(abTestName));
    }
  }, []);


  const handleRedirectToAbTestDetails = (response, data) => {
    if (isEmpty(response.payload.errors)) {
      navigate(urlPageAbTestDetails({ abTestName: data.name }));
    }
  };

  const handleSubmitFormData = async (values) => {
    const data = preparedToRequestFormData(values);

    if (isCreateAbTestPage) {
      await dispatch(createAbTest(data)).then((response) => {
        if (isFulfilledRequestStatus(response)) {
          handleRedirectToAbTestDetails(response, data);
        }
      });
    } else {
      await dispatch(updateAbTest({ abTestName, data })).then((response) => {
        handleRedirectToAbTestDetails(response, data);
      });
    }
  };


  const abTestInitialValues = !isCreateAbTestPage ? getPreparedFormData(abTestDetails?.data) : CREATE_AB_TEST_INITIAL_VALUES;
  const defaultAbTestStatus = !isCreateAbTestPage ? abTestDetails.status : null;

  return (
    <HasRights allowedPermissions={isCreateAbTestPage ? [ Permissions.AB_TESTS.CREATE ] : [ Permissions.AB_TESTS.UPDATE ]}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={mkRedirectToDashboardPath(projectSettings)}>
            Home
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={urlPageAbTests()}>
            Ab-tests list
          </Link>
        </Breadcrumb.Item>

        {!isEmpty(abTestName) && (
          <Breadcrumb.Item>
            <Link to={urlPageAbTestDetails({ abTestName })}>
              {abTestName}
            </Link>
          </Breadcrumb.Item>
        )}

        <Breadcrumb.Item>
          {!isCreateAbTestPage ? 'Edit details' : 'Create'}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Spin spinning={isLoading || isCreateLoading || isUpdateLoading} tip="Loading...">
        <FormCreateEditAbTest
          initialValues={abTestInitialValues}
          isAbTestEdit={!isCreateAbTestPage}
          defaultAbTestStatus={defaultAbTestStatus}
          formErrors={updateErrors || createErrors}
          segmentList={segmentList}
          onCancel={() => {
            navigate(-1);
          }}
          isSubmitting={isCreateLoading || isUpdateLoading}
          onSubmit={handleSubmitFormData}
        />
      </Spin>
    </HasRights>
  );
};

export default PageCreateEditAbTest;
