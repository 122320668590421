import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Table, message, Input, Row, Col, Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { useApp } from '../../../../app/context/AppContext';
import { getQueryParams } from '../../../../lib/getQueryParams';
import { mkQueryParams } from '../../../../lib/mkQueryParams';
import {
  getSegmentsList,
  selectSegmentsList,
  selectSegmentsLoading,
  selectSegmentPagination,
  deleteSegment,
} from '../../feature/segmentsSlice';
import { getColumns } from './PageSegments.const';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';



const PageSegments = () => {
  const dispatch = useDispatch();
  const { projectSettings } = useApp();
  const { search } = useLocation();
  const queryParams = getQueryParams(search);

  const segments = useSelector(selectSegmentsList);
  const loading = useSelector(selectSegmentsLoading);
  const pagination = useSelector(selectSegmentPagination);

  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);
  const [ searchQuery, setSearchQuery ] = useState('');
  const [ isFirstLoad, setIsFirstLoad ] = useState(true);

  const fetchSegments = () => {
    dispatch(getSegmentsList({
      project: projectSettings?.projectKey,
      ...mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting),
      name: searchQuery,
      include_disabled: 1,
    }));
  };

  const debouncedSearch = debounce((value) => {
    setSearchQuery(value);
    setCurrentPage(1);
  }, 300);

  useEffect(() => {
    if (!projectSettings?.projectKey) return;

    if (isFirstLoad) {
      setIsFirstLoad(false);
    }

    fetchSegments();
  }, [ projectSettings, currentPage, currentLimit, currentOrder, currentSorting, searchQuery ]);



  const handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  const handleDelete = async (segmentName) => {
    try {
      const result = await dispatch(deleteSegment({
        project: projectSettings.projectKey,
        segmentId: segmentName,
      })).unwrap();

      if (result.data.status === 'deleting') {
        message.success('Segment deletion initiated');
        fetchSegments();
      } else {
        message.error('Failed to delete segment');
      }
    } catch (error) {
      message.error(`Failed to delete segment: ${error}`);
    }
  };

  const columns = getColumns({ handleDelete });

  return (
    <>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={mkRedirectToDashboardPath(projectSettings)}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Segments</Breadcrumb.Item>
          </Breadcrumb>

          <Col>
            <Input
              placeholder="Search by name"
              prefix={<SearchOutlined />}
              onChange={(el) => debouncedSearch(el.target.value)}
              style={{ width: 250 }}
              allowClear
              disabled={loading}
            />
          </Col>
        </Row>
      </Col>

      <Card title="Segments" style={{ marginTop: 24 }}>
        <Table
          columns={columns}
          dataSource={segments}
          loading={loading}
          rowKey="id"
          onChange={handleTableChange}
          pagination={{
            size: 'small',
            current: pagination?.current_page,
            pageSize: pagination?.per_page,
            total: pagination?.total,
            position: [ 'bottomCenter' ],
            pageSizeOptions: [ '10', '25', '50' ],
            showSizeChanger: true,
          }}
        />
      </Card>
    </>
  );
};

export default PageSegments;
